<template>
  <div>
    <v-card>
    
    <ContentLoader 
      speed=1
      width="550"
      height="180"
      viewBox="0 0 476 124"
      backgroundColor="#ddebf8"
      foregroundColor="#ffffff"
    >
    <rect x="18" y="13" rx="0" ry="0" width="220" height="200" /> 
    <rect x="260" y="40" rx="0" ry="0" width="200" height="30" /> 
    <rect x="260" y="80" rx="0" ry="0" width="200" height="30" /> 
    <rect x="18" y="250" rx="0" ry="0" width="440" height="80" />
  </ContentLoader>
  </v-card>
    <!-- Outros componentes ou conteúdo da sua aplicação -->
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  components: {
    ContentLoader
  }
};
</script>
