import { http } from './config'
import authHeader from './auth-header';

export default {


    total_esperado_periodo_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        const params = {
         'data-inicial': dataI,
         'data-final': dataF,
         'empresa': nomeEmpresa,
         'tipo': tipo,
         'filtro': filtro,
         'opcao': opcao,
         'produto': produto,
     }
         return http.get('/financeiro-dash/qtd-esperado-periodo-v2/', {
             params: params,
             headers: authHeader() 
         })
     },



     total_recebimento_vencimento_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, dataI2, dataF2, opcao, produto) => {
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'data-inicial-2': converteParaNull(dataI2),
            'data-final-2': converteParaNull(dataF2),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }

        return http.get('/financeiro-dash/qtd-recebimento-vencimento-v2/', {
            params: params,
            headers: authHeader() 
        })

    },


    recebido_adiantado_vencimento_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
           
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }

        return http.get('/financeiro-dash/qtd-recebido-adiantado-vencimento-v2/', {
            params: params,
            headers: authHeader() 
        })
    },

        total_recebimento_30_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
           
            const params = {
                'data-inicial': dataI,
                'data-final': dataF,
                'empresa': nomeEmpresa,
                'tipo': tipo,
                'filtro': filtro,
                'opcao': opcao,
                'produto': produto,
            }
    
            return http.get('/financeiro-dash/total-recebimento-30-v2/', {
                params: params,
                headers: authHeader() 
            })

    },


    

    total_esperado_periodo_aberto_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
       

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }

        return http.get('/financeiro-dash/total-esperado-periodo-aberto-v2/', {
            params: params,
            headers: authHeader() 
        })

    },


    total_recebimento_30_60_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
       

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }

        return http.get('/financeiro-dash/total-recebimento-30-60-v2/', {
            params: params,
            headers: authHeader() 
        })

    },


                            
                            


 
    inadimplencia_acumulda: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
       

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }

        return http.get('/financeiro-dash/qtd-esperado-periodo-aberto-v2/', {
            params: params,
            headers: authHeader() 
        })

    },


    total_recebimento_60_v2: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
      
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }

        return http.get('/financeiro-dash/total-recebimento-60-v2/', {
            params: params,
            headers: authHeader() 
        })

    },
    


   
   

}
